import {
  DateArgument,
  Timezones,
  Nullable,
  convertToTimezone as convertToTimezoneUtil,
  setYear as setYearUtil,
  setMonth as setMonthUtil,
  roundToMinutes as roundToMinutesUtil,
  isSameYear as isSameYearUtil,
  isSameMonth as isSameMonthUtil,
  isSameDay as isSameDayUtil,
  isSameDate as isSameDateUtil,
  isDate as isDateUtil,
  getYear as getYearUtil,
  getStartOfYear as getStartOfYearUtil,
  getStartOfMonth as getStartOfMonthUtil,
  getStartOfDay as getStartOfDayUtil,
  getRandomDate as getRandomDateUtil,
  getProgressBetweenDates as getProgressBetweenDatesUtil,
  getMonthName as getMonthNameUtil,
  getMonth as getMonthUtil,
  getLastDayInMonth as getLastDayInMonthUtil,
  getJSDate,
  getFirstDayInMonth as getFirstDayInMonthUtil,
  getEndOfYear as getEndOfYearUtil,
  getEndOfMonth as getEndOfMonthUtil,
  getEndOfDay as getEndOfDayUtil,
  getDiffInDays as getDiffInDaysUtil,
  getDiffBetweenTimezones as getDiffBetweenTimezonesUtil,
  getDayOfWeekName as getDayOfWeekNameUtil,
  getDayOfWeek as getDayOfWeekUtil,
  getDayOfMonth as getDayOfMonthUtil,
  addMonths as addMonthsUtil,
  addHours as addHoursUtil,
  addDays as addDaysUtil,
  WeekNames,
  MonthNames,
} from "@epcnetwork/core-ui-kit";

export class DateMapper {
  private timezone: Timezones = "Etc/UTC";
  private timestamp: number;

  constructor(
    date: DateArgument = Date.now(),
    toTimezone: Timezones = "Etc/UTC",
    dateTimezone: Timezones = "Etc/UTC",
  ) {
    this.timezone = toTimezone;
    this.timestamp = +convertToTimezoneUtil(date, toTimezone, dateTimezone);
  }

  // Presentation ↓ (Not returning class, but some value according to logic sense)
  toLocaleString(): string {
    return getJSDate(this.timestamp).toLocaleString();
  }

  toUTCString(): string {
    return getJSDate(this.timestamp).toUTCString();
  }

  toISOString(): string {
    return getJSDate(this.timestamp).toISOString();
  }

  toJSDate(): Date {
    return getJSDate(this.timestamp);
  }

  getMonth(inUTC?: boolean): number {
    return getMonthUtil(this.timestamp, inUTC);
  }

  getYear(inUTC?: boolean): number {
    return getYearUtil(this.timestamp, inUTC);
  }

  getDayOfMonth(inUTC?: boolean): number {
    return getDayOfMonthUtil(this.timestamp, inUTC);
  }

  getDayOfWeek(inUTC?: boolean): number {
    return getDayOfWeekUtil(this.timestamp, inUTC);
  }

  getDayOfWeekName(inUTC?: boolean): WeekNames {
    return getDayOfWeekNameUtil(this.timestamp, inUTC);
  }

  getDiffBetweenTimezone(rightTz: Timezones, absResult?: boolean): number {
    return getDiffBetweenTimezonesUtil(this.timezone, rightTz, absResult);
  }

  getDiffInDays(rightDate: DateArgument, asCoefficient?: boolean): number {
    return getDiffInDaysUtil(this.timezone, rightDate, asCoefficient);
  }

  getMonthName(inUTC?: boolean): MonthNames {
    return getMonthNameUtil(this.timezone, inUTC);
  }

  getProgressBetweenDates(startDate: DateArgument, endDate: DateArgument): number {
    return getProgressBetweenDatesUtil(startDate, endDate, this.timezone);
  }

  isSameDate(withDate: Nullable<DateArgument>): boolean {
    return isSameDateUtil(this.timestamp, withDate);
  }

  isSameDay(withDate: Nullable<DateArgument>, inUTC?: boolean): boolean {
    return isSameDayUtil(this.timestamp, withDate, inUTC);
  }

  isSameMonth(withDate: Nullable<DateArgument>, inUTC?: boolean): boolean {
    return isSameMonthUtil(this.timestamp, withDate, inUTC);
  }

  isSameYear(withDate: Nullable<DateArgument>, inUTC?: boolean): boolean {
    return isSameYearUtil(this.timestamp, withDate, inUTC);
  }

  // Presentation ↑

  // Looped ↓ (Returning class according to builder pattern)
  addDays(days: number): DateMapper {
    this.timestamp = +addDaysUtil(days, this.timestamp);
    return this;
  }

  addHours(hours: number): DateMapper {
    this.timestamp = addHoursUtil(hours, this.timestamp);
    return this;
  }

  addMonths(months: number): DateMapper {
    this.timestamp = addMonthsUtil(months, this.timestamp);
    return this;
  }

  convertToTimezone(toTimezone: Timezones): DateMapper {
    this.timezone = toTimezone;
    this.timestamp = +convertToTimezoneUtil(this.timestamp, toTimezone, this.timezone);
    return this;
  }

  getEndOfDay(inUTC?: boolean): DateMapper {
    this.timestamp = +getEndOfDayUtil(this.timestamp, inUTC);
    return this;
  }

  getEndOfMonth(inUTC?: boolean): DateMapper {
    this.timestamp = +getEndOfMonthUtil(this.timestamp, inUTC);
    return this;
  }

  getEndOfYear(inUTC?: boolean): DateMapper {
    this.timestamp = +getEndOfYearUtil(this.timestamp, inUTC);
    return this;
  }

  getFirstDayInMonth(inUTC?: boolean): DateMapper {
    this.timestamp = +getFirstDayInMonthUtil(this.timestamp, inUTC);
    return this;
  }

  getLastDayInMonth(inUTC?: boolean): DateMapper {
    this.timestamp = +getLastDayInMonthUtil(this.timestamp, inUTC);
    return this;
  }

  getStartOfDay(inUTC?: boolean): DateMapper {
    this.timestamp = +getStartOfDayUtil(this.timestamp, inUTC);
    return this;
  }

  getStartOfMonth(inUTC?: boolean): DateMapper {
    this.timestamp = +getStartOfMonthUtil(this.timestamp, inUTC);
    return this;
  }

  getStartOfYear(inUTC?: boolean): DateMapper {
    this.timestamp = +getStartOfYearUtil(this.timestamp, inUTC);
    return this;
  }

  roundToMinutes(minute: number): DateMapper {
    this.timestamp = +roundToMinutesUtil(this.timestamp, minute);
    return this;
  }

  setMonth(month: number): DateMapper {
    this.timestamp = +setMonthUtil(month, this.timestamp);
    return this;
  }

  setYear(year: number, inUTC?: boolean): DateMapper {
    this.timestamp = +setYearUtil(year, this.timestamp, inUTC);
    return this;
  }

  // Looped ↑

  // Static ↓
  static addDays = addDaysUtil;

  static addHours = addHoursUtil;

  static addMonths = addMonthsUtil;

  static convertToTimezone = convertToTimezoneUtil;

  static getDayOfMonth = getDayOfMonthUtil;

  static getDayOfWeek = getDayOfWeekUtil;

  static getDayOfWeekName = getDayOfWeekNameUtil;

  static getDiffBetweenTimezones = getDiffBetweenTimezonesUtil;

  static getDiffInDays = getDiffInDaysUtil;

  static getEndOfDay = getEndOfDayUtil;

  static getEndOfMonth = getEndOfMonthUtil;

  static getEndOfYear = getEndOfYearUtil;

  static getFirstDayInMonth = getFirstDayInMonthUtil;

  static getJSDate = getJSDate;

  static getLastDayInMonth = getLastDayInMonthUtil;

  static getMonth = getMonthUtil;

  static getMonthName = getMonthNameUtil;

  static getProgressBetweenDates = getProgressBetweenDatesUtil;

  static getRandomDate = getRandomDateUtil;

  static getStartOfDay = getStartOfDayUtil;

  static getStartOfMonth = getStartOfMonthUtil;

  static getStartOfYear = getStartOfYearUtil;

  static getYear = getYearUtil;

  static isDate = isDateUtil;

  static isSameDate = isSameDateUtil;

  static isSameDay = isSameDayUtil;

  static isSameMonth = isSameMonthUtil;

  static isSameYear = isSameYearUtil;

  static roundToMinutes = roundToMinutesUtil;

  static setMonth = setMonthUtil;

  static setYear = setYearUtil;

  static now = Date.now;

  // Static ↑

  [Symbol.toPrimitive] = (hint: "string" | "number" | "default"): string | number => {
    switch (hint) {
      case "string":
        return getJSDate(this.timestamp).toISOString();
      case "number":
      default:
        return this.timestamp;
    }
  };
}
