import { ServerErrorTypes } from "api/api.middleware";
import { ServerErrorType } from "types/errors.type";

export const getError = (originalError: ServerErrorTypes): string | null => {
  const serverOriginalError = originalError as ServerErrorType;

  if (!!serverOriginalError.context.length) {
    return serverOriginalError.context[0].error;
  }
  return null;
};
