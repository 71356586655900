import React, { FC, memo, useState, useRef, MouseEvent } from "react";
import cn from "classnames";
import {
  useScroll,
  useResize,
  useWindowEvent,
  TextEllipsis,
  formatDate,
  getJSDate,
  isWithinRange,
  applyCSSToElement,
  removeCSSFromElement,
  negativeCondition,
  Nullable,
  CollapseSelfControlled,
} from "@epcnetwork/core-ui-kit";

import {
  panningMovementWidth,
  panningMultiplier,
  GanttTimelineContext,
} from "./gantt-timeline.constants";
import { getDateWithTimezone } from "utils";
import { GanttGroupType, VisibleRangeType } from "./gantt-timeline.types";
import {
  getGanttView,
  handleCalcShift,
  handleCalcZoom,
  getGanttDatePeriods,
} from "./gantt-timeline.utils";
import { DateMapper } from "utils";
import GanttCap from "./components/gantt-cap";
import GanttCurrentTimeLine from "./components/gantt-current-time-line";
import GanttItem from "./components/gantt-item";
import GanttLegend from "./gantt-legend/gantt-legend";

import styles from "./gantt-timeline.module.css";

export interface GanttTimelineProps {
  title: string;
  data: GanttGroupType[];
  className?: string;
  invertPanning?: boolean;
}

export const GanttTimeline: FC<GanttTimelineProps> = memo(
  ({ title, data, className = "", invertPanning = false }: GanttTimelineProps) => {
    const isInPanning = useRef(false);
    const panningMovementRef = useRef(0);

    const [ganttWrapper, setGanttWrapper] = useState<Nullable<HTMLDivElement>>(null);
    const [timePeriodsWrapper, setTimePeriodsWrapper] = useState<Nullable<HTMLDivElement>>(null);
    const [timePeriodsWidth, setTimePeriodsWidth] = useState(0);

    const animationDuration = 150;

    useResize(timePeriodsWrapper, () => {
      setTimePeriodsWidth(timePeriodsWrapper?.scrollWidth || 0);
    });

    const [{ visibleFrom, visibleTo }, setVisibleRange] = useState<VisibleRangeType>({
      visibleFrom: +new DateMapper().addDays(-15).getStartOfDay(),
      visibleTo: +new DateMapper().addDays(15).getEndOfDay(),
    });

    const currentViewType = useRef(getGanttView(visibleFrom, visibleTo));

    useScroll(ganttWrapper, (e) => {
      if (e.shiftKey) {
        e.preventDefault();

        const { deltaY, ctrlKey, altKey } = e;

        const signMultiplier = -Math.sign(deltaY);
        const valueMultiplier = ctrlKey ? 5 : altKey ? 1 : 2;

        handleCalcShift(valueMultiplier, signMultiplier, currentViewType.current, setVisibleRange);
        return;
      }

      if (!(e.ctrlKey || e.altKey)) return;
      e.preventDefault();

      const [from, to] = handleCalcZoom(e, visibleFrom, visibleTo, currentViewType.current);

      currentViewType.current = getGanttView(from, to);

      setVisibleRange({
        visibleFrom: from,
        visibleTo: to,
      });
    });

    const periods = getGanttDatePeriods(visibleFrom, visibleTo, currentViewType.current);

    setTimeout(() => {
      setTimePeriodsWidth(timePeriodsWrapper?.scrollWidth || 0);
    }, 0);

    const handleClickDown = (e: MouseEvent<HTMLDivElement>) => {
      if (isInPanning.current) return;
      e.preventDefault();
      e.stopPropagation();

      isInPanning.current = true;

      applyCSSToElement(document.body, { cursor: "ew-resize" });
    };

    useWindowEvent("mouseup", () => {
      if (!isInPanning.current) return;

      isInPanning.current = false;
      panningMovementRef.current = 0;
      removeCSSFromElement(document.body, ["cursor"]);
    });

    useWindowEvent("mousemove", (e) => {
      if (!isInPanning.current) return;

      panningMovementRef.current += e.movementX;

      if (Math.abs(panningMovementRef.current) < panningMovementWidth) return;

      const sign = Math.sign(panningMovementRef.current);

      handleCalcShift(
        panningMultiplier,
        negativeCondition(sign, invertPanning),
        currentViewType.current,
        setVisibleRange,
      );

      panningMovementRef.current = 0;
    });

    return (
      <GanttTimelineContext.Provider value={{ timePeriodsWidth }}>
        <div className={cn(styles.container, className)} ref={setGanttWrapper}>
          <GanttCap
            visibleFrom={visibleFrom}
            visibleTo={visibleTo}
            viewType={currentViewType.current}
          />
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
                {title}
              </TextEllipsis>
            </div>
            <div className={styles.timePeriods} ref={setTimePeriodsWrapper}>
              {periods.map(({ key, name, decoration, isPresentDate, isWeekendDate }, index) => {
                const timePeriodStyles = cn(styles.timePeriod, {
                  [styles.presentDate]: isPresentDate,
                  [styles.weekendDay]: isWeekendDate,
                });

                return (
                  <div key={key.toString() + index} className={styles.timePeriodWrapper}>
                    <div className={timePeriodStyles}>
                      {name}
                      {decoration && <span className={styles.periodDecoration}>{decoration}</span>}
                      {isPresentDate && <div className={styles.presentDateDecorator} />}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.content} onMouseDown={handleClickDown}>
            <GanttCurrentTimeLine visibleFrom={visibleFrom} visibleTo={visibleTo} />
            {data.map(({ name, items }, index) => {
              return (
                <CollapseSelfControlled
                  key={index}
                  initiallyOpened
                  openDuration={animationDuration}
                  hideDuration={animationDuration}
                  triggerElement={
                    <div
                      onMouseDownCapture={(e) => e.stopPropagation()}
                      className={styles.groupTitle}
                    >
                      {name}
                    </div>
                  }
                >
                  <div className={styles.groupWrapper}>
                    <div className={styles.itemsWrapper}>
                      {items.map((item, index) => {
                        const { name, description, startDate, endDate, legend } = item;

                        const startD = +getJSDate(startDate);
                        const endD = +getJSDate(endDate);

                        const isWithinDates =
                          isWithinRange(startD, visibleFrom, visibleTo) ||
                          isWithinRange(endD, visibleFrom, visibleTo) ||
                          isWithinRange(visibleFrom, startD, endD) ||
                          isWithinRange(visibleTo, startD, endD);

                        return (
                          <div key={name + index} className={styles.itemWrapper}>
                            <div className={cn(styles.titleWrapper, styles.borderRight)}>
                              <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
                                {name}
                              </TextEllipsis>
                              <div className={styles.itemRangesWrapper}>
                                <div className={styles.itemRangeTime}>
                                  {formatDate(getDateWithTimezone(startDate), "MMM d, yyyy")}
                                </div>
                                -
                                <div className={styles.itemRangeTime}>
                                  {formatDate(getDateWithTimezone(endDate), "MMM d, yyyy")}
                                </div>
                              </div>
                            </div>
                            <div className={styles.grid}>
                              {periods.map((period) => (
                                <div className={styles.gridItem} key={period.key}></div>
                              ))}
                            </div>
                            {isWithinDates && (
                              <GanttItem
                                title={name}
                                description={description}
                                visibleFrom={visibleFrom}
                                visibleTo={visibleTo}
                                startDate={startD}
                                endDate={endD}
                                color={legend?.color}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CollapseSelfControlled>
              );
            })}
          </div>
          <GanttLegend data={data} />
        </div>
      </GanttTimelineContext.Provider>
    );
  },
);

export default GanttTimeline;
